<template>
 <section id="home">
    <div class="flexslider" id="home-slider">
        <ul class="slides">
            <li>
                <img src="/images/parts/HomeSlide1.webp" alt="Home slider">
                <div class="slide-wrap">
                    <div class="slide-content">
                        <div class="container text-center">
                            <!--<h1 class="upper">Smart<br>Buildings</h1>-->
                            <img src="/images/BigLogo.webp" alt="Biemme Tecno Logo" />
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</section>
<section class="big-heading" id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 col-sm-4 img-side img-right">
                <div class="img-holder">
                    <img class="bg-img" src="/images/parts/Image008.webp" alt="Fabbricati pubblici">
                    <div class="holder-container"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="col-sm-6">
            <div class="title">
                <h2>Chi siamo</h2>
            </div>
            <div class="row mb-50">
                <div class="col-sm-12">
                    <p class="font-18 mb-25">Azienda specializzata nella progettazione e realizzazione di strutture metalliche nell’ambito civile ed industriale.</p>
                    <p class="font-18 mb-25">BIEMME TECNO, con oltre 20 anni di esperienza maturata nelle costruzioni in acciaio, si avvale di personale tecnico estremamente qualificato.</p>
                    <p class="font-18 mb-25">La realizzazione del progetto viene seguita in ogni fase, dal preventivo sino alla produzione in officina  ed alla posa in opera in cantiere.</p>
                </div>

            </div>
        </div>
    </div>
</section>

<!--<section class="grey-bg pt-0" id="services">
    <div class="container">
        <div class="row" data-negative-margin="150">
            <div class="flexslider image-slider" data-options="{&quot;animation&quot;: &quot;slide&quot;, &quot;directionNav&quot;: true}">
                <ul class="slides">
                    <li>
                        <img src="~/images/Works/Project18.webp" alt="">
                        <div class="slide-caption">
                            <div class="title">
                                <h2>We Love Design</h2>
                            </div>
                            <p class="mt-25 mb-25">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p><a class="upper small-link" href="#"><span>Read More</span><i class="hc-arrow-right"></i></a>
                        </div>
                    </li>
                    <li>
                        <img src="images/slide-image-2.jpg" alt="">
                        <div class="slide-caption">
                            <div class="title">
                                <h2>Minimal Art</h2>
                            </div>
                            <p class="mt-25 mb-25">Mollitia possimus, eligendi voluptatibus, ut perferendis maiores, rem eos autem accusamus soluta porro voluptatum quisquam repudiandae ab culpa.</p><a class="upper small-link" href="#"><span>Discover</span><i class="hc-arrow-right"></i></a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section> -->
<section id="services">
    <div class="container">
        <div class="title center">
            <h2>I nostri servizi</h2>

        </div>
        <div class="row pt-25 pb-25">
            <div class="col-sm-4">
                <div class="team-member">
                    <div class="team-image">
                        <img src="/images/Parts/Progettazione.webp" alt="Progettazione">
                    </div>
                    <div class="team-info">
                        <h3>PROGETTAZIONE</h3>
                        <p>
                            Dallo studio di fattibilità, all’ingegnerizzazione ed alla progettazione esecutiva, i nostri tecnici specializzati
                            con l’ausilio di software di modellazione 3D offrono la possibilità di avere un unico interlocutore per sviluppare ogni singolo progetto.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="team-member">
                    <div class="team-image">
                        <img src="/images/parts/Produzione.webp" alt="Produzione">
                    </div>
                    <div class="team-info">
                        <h3>PRODUZIONE</h3>
                        <p>
                            La carpenteria interna, avvalendosi di personale qualificato e certificato, realizza quanto progettato e richiesto dal cliente.
                            Il sistema di controlli interni ci permette di fornire materiale certificato EN1090 fino alla classe di esecuzione EXC3.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="team-member">
                    <div class="team-image">
                        <img src="/images/Parts/Montaggio.webp" alt="Montaggio">
                    </div>
                    <div class="team-info">
                        <h3>MONTAGGIO</h3>
                        <p>La posa in opera si avvale di personale specializzato e adeguatamente formato, con impiego di mezzi operativi adeguati in base all’esigenza cantieristica.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="p-0" id="works">
    <div class="container">
        <div class="title center p-2">
            <h2>Le nostre realizzazioni</h2>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <ul class="photo-gallery">
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project01.webp" aria-haspopup="true">
                                <img loading="lazy" src="/images/works/project01-thumb.webp" alt="Fabbricati Civili">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Fabbricati civili</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project02.webp" aria-haspopup="true">
                                <img loading="lazy" src="/images/works/project02-thumb.webp" alt="Realizzazioni varie">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Realizzazioni varie</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project03.webp">
                                <img loading="lazy" src="/images/works/project03-thumb.webp" alt="Fabbricati industriali">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Fabbricati industriali</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project04.webp">
                                <img loading="lazy" src="/images/works/project04-thumb.webp" alt="Fabbricati Pubblici">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Fabbricati Pubblici</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project05.webp">
                                <img loading="lazy" src="/images/works/project05-thumb.webp" alt="Scale e recinzioni">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Scale e recinzioni</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project06.webp">
                                <img loading="lazy" src="/images/works/project07-thumb.webp" alt="Realizzazioni varie">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Realizzazioni varie</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project08.webp">
                                <img loading="lazy" src="/images/works/project08-thumb.webp" alt="Realizzazioni varie">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Realizzazioni varie</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project09.webp">
                                <img loading="lazy" src="/images/works/project09-thumb.webp" alt="Realizzazioni civili">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Realizzazioni civili</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project10.webp">
                                <img loading="lazy" src="/images/works/project10-thumb.webp" alt="Realizzazioni civili">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Realizzazioni civili</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project11.webp">
                                <img loading="lazy" src="/images/works/project11-thumb.webp" alt="Fabbricati industriali">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Fabbricati industriali</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project12.webp">
                                <img loading="lazy" src="/images/works/project12-thumb.webp" alt="Fabbricati industriali">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Fabbricati industriali</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project19.webp">
                                <img loading="lazy" src="/images/works/project19-thumb.webp" alt="Fabbricati industriali">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Fabbricati industriali</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project14.webp">
                                <img loading="lazy" src="/images/works/project14-thumb.webp" alt="Soppalchi metallici">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Soppalchi metallici</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project15.webp">
                                <img loading="lazy" src="/images/works/project15-thumb.webp" alt="Fabbricati industriali">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Fabbricati industriali</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="/images/works/project16.webp">
                                <img loading="lazy" src="/images/works/project16-thumb.webp" alt="Realizzazioni varie">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Realizzazioni varie</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
                <li>
                    <figure>
                        <div class="gallery-item">
                            <a href="//images/works/project17.webp">
                                <img loading="lazy" src="/images/works/project17-thumb.webp" alt="Realizzazioni varie">
                                <div class="gallery-caption">
                                    <div class="centrize">
                                        <div class="v-center">
                                            <i class="hc-search"></i>
                                            <h4>Realizzazioni varie</h4>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </figure>
                </li>
            </ul>
        </div>
    </div>
</section>

<section class="grey-bg" id="brochure">
    <div class="container">
        <div class="title center">
            <h2>Scarica la nostra brochure</h2>
        </div>

        <p class="text-center">
            Troverai nel dettaglio alcune delle nostre realizzazioni.
        </p>
        <p class="text-center">
            <a href="/Downloads/Brochure_BiemmeTecno.pdf" target="_blank">
                <img src="/images/parts/Pdf_Download.webp" alt="Download brochure" />
            </a>
        </p>
    </div>
</section>

<section id="contact">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-sm-4 img-side img-left" id="map">
            </div>
        </div>
    </div>
    <div class="container">
        <div class="col-sm-7 col-md-offset-7 col-md-5 col-sm-offset-5">
            <div class="title">
                <h2>Contattaci</h2>
                <h4>Contattaci per maggiori informazioni</h4>
            </div>
            <div class="row pt-25 pb-25">
                <div class="col-sm-12">
                    <div class="icon-box-basic">
                        <i class="hc-map-2 black-text"></i>
                        <h4>Indirizzo</h4>
                        <p>
                            <a href="https://www.google.com/maps/dir//''/@@45.7361725,11.6492106,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4778d024e24c0615:0xf2ae23d56c5c1831!2m2!1d11.69041!2d45.73612!3e0" target="_blank">
                            Via Giorgione 9 - 36061
                            <br>Bassano Del Grappa (VI)
                            </a>
                        </p>
                    </div>
                    <div class="icon-box-basic">
                        <i class="hc-headphones black-text"></i>
                        <h4>Telefono</h4>
                        <p>
                            <a href="tel:+390424568469">0424 568 469</a>
                        </p>
                    </div>
                    <div class="icon-box-basic">
                        <i class="hc-file black-text"></i>
                        <h4>FAX</h4>
                        <p>
                            <a href="tel:+390424566750">0424.566750</a>
                        </p>
                    </div>
                    <div class="icon-box-basic">
                        <i class="hc-mail-open-file black-text"></i>
                        <h4>Email</h4>
                        <p>
                            <a href="mailto:info@biemmetecno.com">info@biemmetecno.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>


